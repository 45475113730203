import { useSelector, useDispatch } from "react-redux";
import { setUser, initialState } from "store/auth/userSlice";
import {
  onSignInSuccess,
  onSignOutSuccess,
  setToken,
} from "store/auth/sessionSlice";
import appConfig from "configs/app.config";
import { REDIRECT_URL_KEY } from "constants/app.constant";
import { useNavigate } from "react-router-dom";
import useQuery from "./useQuery";
import { BACKEND_SERVER_URL } from "constants/api.constant";

function useAuth() {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const query = useQuery();

  const { token, signedIn, expired } = useSelector(
    (state) => state.auth.session
  );

  const signIn = async ({ email, password }) => {
    const formData = new URLSearchParams();
    formData.append("email", email);
    formData.append("password", password);

    try {
      const resp = await fetch(`${BACKEND_SERVER_URL}admin/signIn`, {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: formData,
      });
      const respo = await resp.json();

      if (respo.data && respo.token) {
        const token = respo.token;

        dispatch(setToken(token));

        dispatch(onSignInSuccess(token));

        if (respo.data) {
          dispatch(
            setUser(
              respo.data || {
                userName: "Anonymous",
                authority: ["USER"],
                email: "",
              }
            )
          );
        }

        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);

        return {
          status: "success",
          message: "login done",
          data: respo.data,
        };
      } else {
        return {
          status: "failed",
          message: "Failed to log in, Please Check Your Credentials",
        };
      }
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const handleSignOut = () => {
    dispatch(onSignOutSuccess());
    dispatch(setUser(initialState));

    navigate(appConfig.unAuthenticatedEntryPath);
  };

  const signOut = async () => {
    try {
      handleSignOut();
    } catch (errors) {
      handleSignOut();
    }
  };

  return {
    authenticated: token && signedIn && expired > new Date().getTime(),
    signIn,
    signOut,
  };
}

export default useAuth;
